import { Component, OnInit, Inject, DoCheck } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { interval, Subscription } from 'rxjs';

import { DeviceDetectorService, DeviceInfo } from 'ngx-device-detector';

import { UserService } from 'src/app/services/user.service';
import { MessageService } from 'primeng/api';

import { global } from 'src/app/services/global';

@Component({
  selector: 'mosaic',
  templateUrl: './app-mosaic.component.html',
  styleUrls: ['./app-mosaic.component.scss'],
  providers: [UserService, MessageService],
})
export class AppMosaicComponent implements OnInit, DoCheck {
  private updateSubscription: Subscription | undefined;
  private updateSubscription2: Subscription | undefined;
  appList = global.appList;
  identity: any;
  token: string = '';

  constructor(
    private _userService: UserService,
    private _messageService: MessageService,
    private _deviceService: DeviceDetectorService,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.token = this._userService.getToken();
  }

  ngOnInit(): void {
    this.loadUser();
    this.compruebaDisponibilidad();
    this.updateSubscription2 = interval(5000).subscribe((val) => {
      this.loadUser();
    });

    this.formatearInfoDispositivo();
  }

  selected(app: any) {
    if (app.type == 'app') {
      if (app.available) this.iniciarCarga(app.redirect);
      else this.nonAvailable();
    } else {
      this.checkFunctionSelected(app);
    }
  }

  ngDoCheck() {
    this.loadUser();
  }

  private checkFunctionSelected(app: any) {
    switch (app.app) {
      case 'set-online':
        console.log('Empiezo a trabajar');
        this.changeWorkStatus(0);
        break;
      case 'set-offline':
        console.log('Dejo de trabajar');
        this.changeWorkStatus(1);
        break;
      default:
        console.log('No sé que hacer');
        break;
    }
  }

  private changeWorkStatus(type: number) {
    if (type == 0) {
      this._userService.startWork(this.identity.sub, this.token).subscribe(
        (response) => {
          if (response.code == 200) {
            response.info
              ? this.showChangeWorkStatus(2)
              : this.showChangeWorkStatus(0);
          } else {
            this.showChangeWorkStatus(1);
          }
        },
        (error) => {
          console.log(<any>error);
          this.showChangeWorkStatus(1);
        }
      );
      this.identity.status = 'ONLINE';
      localStorage.setItem('identity', JSON.stringify(this.identity));
    } else if (type == 1) {
      this.showChangeWorkStatus(99);
      this._userService.stopWork(this.identity.sub, this.token).subscribe(
        (response) => {
          if (response.code == 200) {
            response.info
              ? this.showChangeWorkStatus(3)
              : this.showChangeWorkStatus(0);
          } else {
            this.showChangeWorkStatus(1);
          }
        },
        (error) => {
          console.log(<any>error);
          this.showChangeWorkStatus(1);
          this.showChangeWorkStatus(99);
        }
      );
      this.onDataReady();
    }
  }

  private iniciarCarga(redirection: string) {
    this.updateSubscription = interval(200).subscribe((val) => {
      console.log('subscrito');
      this.document.location.href =
        global.entorno == 'produccion'
          ? global.redirecciones(redirection)
          : global.entorno == 'preproduccion'
          ? global.redireccionesPreproduccion(redirection)
          : global.redireccionesLocal(redirection);
      this.updateSubscription?.unsubscribe();
    });
  }

  private loadUser() {
    this.identity = this._userService.getIdentity();
  }

  private nonAvailable() {
    this._messageService.add({
      severity: 'error',
      summary: 'Error',
      detail: 'No tienes acceso a esa APP',
    });
  }

  private showChangeWorkStatus(num: number) {
    switch (num) {
      case 0:
        this._messageService.add({
          severity: 'success',
          summary: 'Exito',
          detail: 'Se ha cambiado el estado',
        });
        break;
      case 1:
        this._messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Error al cambiar de estado',
        });
        break;
      case 2:
        this._messageService.add({
          severity: 'success',
          summary: 'Exito',
          detail: 'Ya estaba abierta la sesión en otro dispositivo',
        });
        break;
      case 3:
        this._messageService.add({
          severity: 'success',
          summary: 'Exito',
          detail: 'Ya estaba cerrada la sesión en otro dispositivo',
        });
        break;
      case 99:
        this._messageService.add({
          severity: 'info',
          summary: 'Info',
          detail: 'Cerrando sesión',
        });
        break;

      default:
        break;
    }
  }

  private compruebaDisponibilidad() {
    let listaUsuario = this.identity.spa_list.split(',');
    this.appList.forEach((app) => {
      app.available = listaUsuario.includes(app.redirect);
    });
  }

  private onDataReady() {
    setTimeout(() => {
      localStorage.clear();
    }, 1000);
  }

  private formatearInfoDispositivo(): string {
    let deviceInfo = this._deviceService.getDeviceInfo();

    deviceInfo.browser = deviceInfo.browser.includes('Edge')
      ? 'Edge'
      : deviceInfo.browser;

    let info_a_enviar = '';

    if (!deviceInfo.device.includes('Unknown')) {
      info_a_enviar = info_a_enviar + deviceInfo.device + ', ';
    }

    info_a_enviar =
      info_a_enviar +
      deviceInfo.deviceType +
      ', ' +
      deviceInfo.os_version +
      ', ' +
      deviceInfo.browser +
      ' v' +
      deviceInfo.browser_version;

    console.log(info_a_enviar);

    return info_a_enviar;
  }
}
